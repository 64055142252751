import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Regulamento = find("[js-page='regulamento']")
	if (!Regulamento) return



	document.addEventListener('DOMContentLoaded', () => {
		const faqItems = document.querySelectorAll('.faq-item');
	
		faqItems.forEach(item => {
			const question = item.querySelector('.faq-question');
			const answer = item.querySelector('.faq-answer');
	
			question.addEventListener('click', () => {
				
				answer.classList.toggle('active');
	
				faqItems.forEach(otherItem => {
					if (otherItem !== item) {
						const otherAnswer = otherItem.querySelector('.faq-answer');
						otherAnswer.classList.remove('active');
					}
				});
			});
		});
	});
}