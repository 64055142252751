import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Categorias = find("[js-page='super-juri']")
	if (!Categorias) return

	const swiperCategoriaSuperJuri = new Swiper(".swiperCategoriaSuperJuri", {
		slidesPerView: 'auto',
		spaceBetween: 12,
		// autoplay: true,
		// loop: true,
		navigation: {
			nextEl: ".swiper-categoria-next",
			prevEl: ".swiper-categoria-prev",
		}
	});

	const swiperCategoriaSuperJuriMobile = new Swiper(".swiper-categorias-superjuri-mobile", {
		slidesPerView: 'auto',
		spaceBetween: 16
	});

	const changeCategoriaJurados = () => {
		const categoriasHeader = document.querySelectorAll('[data-categoria-jurado]')
		const textoCategoriaJurados = document.querySelectorAll('[data-icon-categoria-superjuri]')
		const juradosCategorias = document.querySelectorAll('[data-categoria-jurado-all]')
		const juradosCategoriasMobile = document.querySelectorAll('[data-categoria-jurado-mobile-all]')
		const categoriasComJurados = document.querySelectorAll('[data-cat]')

		categoriasHeader?.forEach(categoria => {
			categoria.addEventListener('click', () => {
				categoriasHeader.forEach(cat => {
					cat.classList.remove('bg-cor-1')
				})

				categoria.classList.add('bg-cor-1')

				textoCategoriaJurados?.forEach(texto => {
					if (texto.dataset.iconCategoriaSuperjuri === categoria.dataset.categoriaJurado) {
						//ocultando todos os itens de texto-categoria
						textoCategoriaJurados.forEach(texto => {
							texto.classList.add('hidden')
						})
						//Exibindo itens de text-categoria
						texto.classList.remove('hidden')
						texto.classList.add('flex')
					}
				})

				juradosCategorias?.forEach(jurado => {
					if (jurado.dataset.categoriaJuradoAll === categoria.dataset.categoriaJurado) {
						juradosCategorias.forEach(cat => {
							cat.classList.add('hidden')
						})

						jurado.classList.remove('hidden')
						jurado.classList.add('grid')
						//console.log('jurados IF', jurado.dataset.categoriaJuradoAll);
					}
				})

				juradosCategoriasMobile?.forEach(jurado => {
					if (jurado.dataset.categoriaJuradoMobileAll === categoria.dataset.categoriaJurado) {
						juradosCategoriasMobile.forEach(cat => {
							cat.classList.add('hidden')
						})

						jurado.classList.remove('hidden')
						jurado.classList.add('grid')
						//console.log('jurados IF', jurado.dataset.categoriaJuradoMobileAll);
					}
				})
			})
		})

		// Animação image //
		categoriasHeader?.forEach(categoria => {
			categoria.addEventListener('click', () => {
				const imgColor = categoria.querySelector('.image-color')
				const imgPb = categoria.querySelector('.image-pb')

				const allImageColor = document.querySelectorAll('.image-color')
				const allImagePb = document.querySelectorAll('.image-pb')

				allImageColor.forEach(i => {
					i.classList.remove('hidden')
				})

				allImagePb.forEach(i => {
					i.classList.add('hidden')
				})

				imgColor.classList.add('hidden')
				imgPb.classList.remove('hidden')
			})
		})

		// Exibe somente categorias com jurados cadastrados //
		categoriasHeader?.forEach(categoria => {
			categoriasComJurados.forEach(cat => {
				categoria.classList.add('hidden')
			})

			categoriasComJurados.forEach((cat, index) => {
				if (categoria.dataset.categoriaJurado === cat.dataset.cat) {
					categoria.classList.remove('hidden')
					if (index == 0) {
						const imgColor = categoria.querySelector('.image-color')
						const imgPb = categoria.querySelector('.image-pb')

						imgColor.classList.add('hidden')
						imgPb.classList.remove('hidden')
						categoria.classList.add('bg-cor-1')
					}
				}
			})
		})

		// Exibe somente texto das categorias com jurados cadastrados //
		textoCategoriaJurados.forEach(categoria => {
			categoriasComJurados.forEach(catJurado => {
				if (categoria.dataset.iconCategoriaSuperjuri === catJurado.dataset.cat)
					console.log('Cat c/ jurado', categoria.dataset.iconCategoriaSuperjuri, catJurado.dataset.cat);
			})
		})

		juradosCategorias.forEach(categoria => {
			categoriasComJurados.forEach((catJurado, index) => {
				if (categoria.dataset.categoriaJuradoAll === catJurado.dataset.cat) {
					if (index == 0) {
						categoria.classList.remove('hidden')
						categoria.classList.add('grid')
						console.log('Cat c/ jurado bottom', categoria.dataset.categoriaJuradoAll, catJurado.dataset.cat);
					}
				}
			})
		})

		juradosCategoriasMobile.forEach(categoria => {
			categoriasComJurados.forEach((catJurado, index) => {
				if (categoria.dataset.categoriaJuradoMobileAll === catJurado.dataset.cat) {
					if (index == 0) {
						categoria.classList.remove('hidden')
						categoria.classList.add('grid')
						console.log('Cat c/ jurado bottom', categoria.dataset.categoriaJuradoMobileAll, catJurado.dataset.cat);
					}
				}
			})
		})

	}

	function richTextParseSuperjuri() {
		function parseJSONToHTML(json) {
			const data = JSON.parse(json);

			let html = "";

			for (const block of data.blocks) {
				switch (block.type) {
					case "paragraph":
						html += `<p class="paragraph">${block.data.text}</p>`;
						break;
					case "Header":
						html += `<h${block.data.level} class="header">${block.data.text}</h${block.data.level}>`;
						break;
					case "Table":
						html += `<table class="table">${block.data.content}</table>`;
						break;
					case "Raw":
						html += `<div class="raw">${block.data.html}</div>`;
						break;
					case "Delimiter":
						html += '<hr class="delimiter">';
						break;
					case "Code":
						html += `<pre class="code"><code>${block.data.code}</code></pre>`;
						break;
					case "Quote":
						html += `<blockquote class="quote">${block.data.text}</blockquote>`;
						break;
					case "List":
						const listItems = block.data.items
							.map((item) => `<li>${item}</li>`)
							.join("");
						const listStyle = block.data.style === "ordered" ? "ol" : "ul";
						html += `<${listStyle} class="list">${listItems}</${listStyle}>`;
						break;
					case "Embed":
						html += `<div class="embed ${block.data.service}">
									<iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
									</div>`;
						break;
					case "Checklist":
						const checklistItems = block.data.items
							.map((item) => {
								const checked = item.checked ? "checked" : "";
								return `<li>
										<input  type="checkbox" onclick="return false;"  ${checked}>
										<label> ${item.text} </label>
										</li>`;
							})
							.join("");
						html += `<ul>${checklistItems}</ul>`;
						break;
					case "Image":
						html += `<img src="${block.data.file.url}" alt="${block.data.caption}" class="image">`;
						break;
					case "Warning":
						html += `<div class="warning">
								<h3>${block.data.title}</h3>
								<p>${block.data.message}</p>
								</div>`;
						break;
					default:
						// Handle unknown block types here
						break;
				}
			}

			return html;
		}

		const blogContent = document.querySelectorAll(".text-content-biografia-superjuri");
		blogContent.forEach((blog) => {
			if (blog.dataset.conteudoDescricaoBiografia) {
				if (
					JSON.parse(blog.dataset.conteudoDescricaoBiografia).blocks.length > 0
				) {
					const htmlParsed = parseJSONToHTML(
						blog.dataset.conteudoDescricaoBiografia
					);
					blog.innerHTML = htmlParsed;
				} else {
					blog.closest("div").style.display = "none";
				}
			} else {
				blog.closest("div").style.display = "none";
			}
		});
	}

	richTextParseSuperjuri()
	changeCategoriaJurados()


}