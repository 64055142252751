import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

import { find, findAll } from "utils/dom"

import Swiper from "swiper"



export default function pageIndicacaoFase2 () {
	const Indicacao = find("[js-page='indicacao']")
	if (!Indicacao ) return
	if(Indicacao.id !== 'indicacao-publica') return

	console.log('build 15:50') 
	// alert('oie') 
	let currentIndicationStep = 1
	let indicatedQuantity = 0
	const nextStepAction = find('[data-next-step]')
	const submitButton = find('[data-submit-form]')
	const dataTabSection = findAll('[data-step-content]')
	const dataCategoryButtons = findAll('#votacao a[data-category-button]')
	const allCategoryContents = document.querySelectorAll('[data-category-content]')

	const dataQtdItensIndicated = findAll('[data-qtd-itens-indicated]')
	const swiperVotacaoFase = findAll('.swiper-votacao-fase-2 .swiper-slide')



	function alertRequiredFields(fields, txt, verify = true) {
		fields.map(input => {
			const inputParent = input?.parentNode
			const requiredFieldParagraph = inputParent.parentNode.querySelector('p.text-paragraph-4')



			requiredFieldParagraph?.remove()

			if ((verify && !input.checked) && input.type === "checkbox") {
				inputParent.insertAdjacentHTML('afterend', `
					<p class="text-paragraph-4" style="color: rgb(220, 38, 38)">
						${txt}
					</p>
				`)
			} else if ((verify && !input.value) || !verify) {

				inputParent.insertAdjacentHTML('afterend', `
					<p class="text-paragraph-4" style="color: rgb(220, 38, 38)">
						${txt}
					</p>
				`)
			}
		})
	}
	function verifyFieldsOnSubmit(event) {
	
		const formElement = find('form')
		const inputFields = findAll('[data-field-required="true"]')
		const inputValues = inputFields.map(v => v.value)

		

		// if (!formElement.checkValidity()) {
		// 	event.preventDefault(); // Prevent form submission if invalid
		// 	formElement.reportValidity(); // This will show native validation messages
		// }

		// return

		const hasEmptyInput = inputFields.some((input) => {
			if (input.type === 'checkbox' || input.type === 'radio') {
				return !input.checked;
			}
			return !input.value;
		});



		inputFields
			.filter(input => input.name.startsWith("valor-indicado"))
			.map(input => {
				const count = inputValues.filter(i => i === input.value && input.value || '').length

				if (count > 1) {
					alertRequiredFields([input], 'Indicado repetido', false)
				}
			})

		inputFields.filter((input) => {
			if (input.type == 'checkbox') {
				return input;
			}
		}).map((input) => {
			const is_checked = (input.checked ? true : false)
			if (is_checked === false) {
				alertRequiredFields([input], 'Campo obrigatório')
				console.log(input)
			}
		})

		if (hasEmptyInput) {
			alertRequiredFields(inputFields, 'Campo obrigatório')

		}
		 else if (Indicacao.id == 'indicacao-publica') {
			const repeatedFields = inputFields
				.filter(input => input.name.startsWith("valor-indicado"))
				.map(input => {
					const count = inputValues.filter(i => i === input.value && input.value || '').length

					if (count > 1)
						alertRequiredFields([input], 'Indicado repetido', false)

					return count
				})
				

				var aceitaReceberComunicacao = document.querySelector('input[name="aceita_receber_comunicacao"]');
					if(aceitaReceberComunicacao.checked){
						aceitaReceberComunicacao.value = 1
					}else {
						aceitaReceberComunicacao.value = 0
					}					
			!repeatedFields.some(i => i > 1) && formElement.submit()
		}
	}
	function updateIndicationSteps() {

		const indicationStepMarks = findAll("[data-step-mark]")
		const indicationSteps = findAll("[data-step-content]")

		indicationSteps?.map(step => step.classList.add('hidden'))
		indicationSteps.find((step => +step.dataset.stepContent === currentIndicationStep))
			?.classList.remove('hidden')

		indicationStepMarks?.map(el => {
			const step = +el.dataset.stepMark

			if (step <= currentIndicationStep) {
				el.style.background = 'rgb(239, 187, 110)'
				el.style.color = '#000'
			}
		})
	}

	
	function verifySwipersBACKUP() {
		const categorias = findAll('[data-categoria-id]');
		let allVoted = true;
	
		categorias.forEach(categoria => {
			const swiperSlides = categoria.querySelectorAll('.swiper-slide');
			const hasVoted = Array.from(swiperSlides).some(slide => slide.classList.contains('atleta-escolhido'));
	
			if (!hasVoted) {
				allVoted = false;
	
				// Calcular a posição do elemento e adicionar 100px de margem no topo
				const categoriaTop = categoria.getBoundingClientRect().top + window.scrollY - 100;
				window.scrollTo({ top: categoriaTop, behavior: 'smooth' });
	
			
				// alert('Você deve selecionar um candidato para cada categoria antes de continuar.');
	
				// Interrompe a verificação no primeiro swiper não votado encontrado
				return false;
			}
		});


		if(!allVoted){
				// Exibir o alerta
				Toastify({
					text: 'Selecione ao menos um candidato .',
					backgroundColor: "linear-gradient(to right, #FF5F6D, #FFC371)", // Orange to red gradient
			}).showToast();
		}
	
		return allVoted;
	}
	function verifySwipers() {
    const categorias = findAll('[data-categoria-id]');
    let hasVoted = false;

    // Verifica se existe algum atleta escolhido em qualquer categoria
    categorias.forEach(categoria => {
        const swiperSlides = categoria.querySelectorAll('.swiper-slide');
        if (Array.from(swiperSlides).some(slide => slide.classList.contains('atleta-escolhido'))) {
            hasVoted = true;
            return;  // Interrompe o loop ao encontrar o primeiro voto
        }
    });

    if (!hasVoted) {
        // Exibir o alerta se nenhum voto foi encontrado
        Toastify({
            text: 'Selecione ao menos um candidato.',
						style:{
							background : "linear-gradient(to right, #efbb6e, #efbb6e)",
							color: '#000',
							fontFamily: 'Bw Stretch, sans serif',
							boxShadow: 'none',
							fontSize: '1.5rem'
						},
        }).showToast();

        // Rola para a primeira categoria
        const firstCategoryTop = categorias[0].getBoundingClientRect().top + window.scrollY - 100;
        window.scrollTo({ top: firstCategoryTop, behavior: 'smooth' });
    }

    return hasVoted;
	}

	if(nextStepAction){
		// NA VOTACAO SUPER JURI  NAO TEM FASE 2 
		nextStepAction.addEventListener('click', () => {

			if (verifySwipers()) {  // Só avança para o próximo passo se todos os swipers tiverem um voto
				currentIndicationStep++;
				updateIndicationSteps();
				find('#anchor').scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})
			}
			
		})
	}

	submitButton?.addEventListener('click', verifyFieldsOnSubmit)

	swiperVotacaoFase.forEach(candidato => {
    candidato.addEventListener('click', (e) => {
        const current = e.currentTarget;
        const parentCategoria = current.closest('[data-categoria-id]');
        const swiperDaCategoria = parentCategoria.querySelectorAll('.swiper-slide');


        if (!current) return;

        // Verifica se o candidato já está selecionado
        if (current.classList.contains('atleta-escolhido')) {
            current.classList.remove('atleta-escolhido');
        } else {
            swiperDaCategoria.forEach(i => i.classList.remove('atleta-escolhido'));
            current.classList.add('atleta-escolhido');
        }

        if (dataQtdItensIndicated) {
					dataQtdItensIndicated.forEach(i => {
					
						i.innerText = findAll('.atleta-escolhido').length;

					})
        }

				const atletasVotados = document.querySelectorAll('[data-category-content]:not(.hidden) .atleta-escolhido').length
				// const totalSelecionadoText = document.querySelector('[data-qtd-itens-categoria]')

        // Atualizar o valor do data-qtd-itens-categoria com o total de itens visíveis
       
        // totalSelecionadoText.textContent = `${atletasVotados}` // Atualizar o texto visível (opcional)


        const categoriaId = current.closest('[data-categoria-id]').getAttribute('data-categoria-id');
        const inputHidden = find(`[name="valor-indicado-${categoriaId}"]`);

        // Limpa o valor do inputHidden se não houver seleção
        if (current.classList.contains('atleta-escolhido')) {
            inputHidden.value = current.getAttribute('data-nome');
        } else {
            inputHidden.value = '';
        }
    });
	});
	

	// dataCategoryButton?.forEach(i =>{
	// 	i?.addEventListener('click', (e) => {
	// 		const target = e.currentTarget
	// 		const idCategoria = target.getAttribute('data-category-button')
	// 		if(!idCategoria) return
	// 		 const contentToShow = find(`[data-category-content="${idCategoria}"]`)
	// 		 console.log(contentToShow)
	// 		 contentToShow.classList.add('hidden')
	// 	})
	// })
	dataCategoryButtons.forEach(button => {
    button.addEventListener('click', (e) => {
        const target = e.currentTarget
        const idCategoria = target.getAttribute('data-category-button')
        
        // Se não houver idCategoria, sair
        if (!idCategoria) return
        
        // Remover a classe 'ativo' de todos os botões
        dataCategoryButtons.forEach(btn => btn.classList.remove('ativo'))
        
        // Adicionar a classe 'ativo' ao botão clicado
        target.classList.add('ativo')
        
        // Se o idCategoria for 'todas', exibir todos os conteúdos
        if (idCategoria === 'todas') {
            allCategoryContents.forEach(content => content.classList.remove('hidden'))
        } else {
            // Ocultar todos os conteúdos
            allCategoryContents.forEach(content => content.classList.add('hidden'))
            
            // Exibir os conteúdos correspondentes ao idCategoria
            const contentsToShow = document.querySelectorAll(`[data-category-content="${idCategoria}"]`)
            if (contentsToShow.length > 0) {
                contentsToShow.forEach(content => {
                    content.classList.remove('hidden')
                })
            } 
        } 

				find('#anchor').scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				})


        // Selecionar todos os itens visíveis (não possuem a classe 'hidden')
        const visibleItems = document.querySelectorAll('[data-category-content]:not(.hidden)').length
        const atletasVotados = document.querySelectorAll('[data-category-content]:not(.hidden) .atleta-escolhido').length

				// const totalCategoriasExibidas = document.querySelector('[data-total-categoria]')
				const totalSelecionadoText = document.querySelector('[data-qtd-itens-categoria]')
				
				totalSelecionadoText.textContent =  `${visibleItems}` 
        // totalCategoriasExibidas.textContent = `${visibleItems}` 
        // totalSelecionadoText.textContent = `${atletasVotados}` 
    })
})
	



	document.addEventListener('DOMContentLoaded', () =>{
		updateIndicationSteps()


		findAll('[data-cep-mask]')?.map(input => IMask(input, {
			mask: '00000-000'
		}))
		findAll('[data-telefone-mask]')?.map(input => IMask(input, {
			mask: [
				{
					mask: '(00) 0000-0000'  // Formato para telefones fixos
				},
				{
					mask: '(00) 00000-0000' // Formato para celulares
				}
			],
			dispatch: function (appended, dynamicMasked) {
				const number = (dynamicMasked.value + appended).replace(/\D/g, '');
		
				// Verifica se o primeiro dígito depois do DDD é 9 (indica celular)
				return number.length > 10 ? dynamicMasked.compiledMasks[1] : dynamicMasked.compiledMasks[0];
			}
		}));

	})



	const swiperAtletas = new Swiper('.swiper-votacao-fase-2',{
		slidesPerView: 1.5,
		spaceBetween: 15,
		centeredSlidesBounds:true,
		centerInsufficientSlides:true,
		// slideToClickedSlide: true,
		centeredSlides:true,
			pagination: {
			el: '.swiper-pagination-custom-categoria-tecnica-2017',
			clickable: true,
			dynamicBullets: true,
						dynamicMainBullets: 3,
		},
		navigation: {
			nextEl: '.swiper-votacao-fase-2 .btn-next',
			prevEl: '.swiper-votacao-fase-2 .btn-prev',
		},
		breakpoints: {
			600:{
				slidesPerView: 'auto',
				spaceBetween: 30,
			}
		}
	})

}