import Swiper from "swiper"
import { find } from "utils/dom"

export default function () {
	const Categorias = find("[js-page='categorias']")
	if (!Categorias) return

	document.addEventListener('DOMContentLoaded', () => {
		const buttons = document.querySelectorAll('.frame button');
		const cards = document.querySelectorAll('.grid-cards > div');
		const todas = document.querySelectorAll('.grid-cards .todas');
		cards.forEach(card => card.classList.add('ativo'));
		cards.forEach(card => card.classList.add('hidden'));


		buttons.forEach(button => {
			button.addEventListener('click', () => {

				buttons.forEach(btn => btn.classList.remove('ativo'));

				button.classList.add('ativo');

				const filter = button.parentElement.id;

				cards.forEach(card => {
					card.classList.remove('ativo');
					setTimeout(() => {
						card.classList.add('hidden');
					}, 200);
				});

				cards.forEach(card => {
					if (card.classList.contains(filter)) {
						card.classList.remove('hidden');
						setTimeout(() => {
							card.classList.add('ativo');
						}, 0);
					}
				});
			});
		});
	});

	const btnCategoriaMobile = () => {
		const btnHref = document.querySelectorAll('.btn-top')

		if (window.screen.width < 1024) {
			btnHref.forEach(btn => {
				btn.href = '#top'
			})
		}

		const addCategoria = () => {
			const categorias = document.querySelectorAll('[data-categoria]')
			categorias.forEach(item => {
				const categoria = item.dataset.categoria
				item.classList.add(categoria)
			})
		}

		addCategoria()
	}

	btnCategoriaMobile()

}