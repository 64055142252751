import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

import { find, findAll } from "utils/dom"

import Swiper from "swiper"



export default function pageIndicacaoFase2SuperJuri () {
	const Indicacao = find("[js-page='indicacao']")
	if (!Indicacao) return
	if(Indicacao.id !== 'indicacao-superjuri') return
	
  
	// alert('oie')
	let currentIndicationStep = 1
	let indicatedQuantity = 0

	const nextStepAction = find('[data-next-step]')
	const submitButton = find('[data-submit-form]')
	const dataTabSection = findAll('[data-step-content]')
	const dataQtdItensIndicated = find('[data-qtd-itens-indicated]')
	const form = find('[name="indicacao-superjuri-form"]')
	const swiperVotacaoFase = findAll('.swiper-votacao-fase-2 .swiper-slide')

	if(!form) return
	const MAX_SELECTION = form.getAttribute('data-max-selection');

	swiperVotacaoFase.forEach(candidato => {
    candidato.addEventListener('click', (e) => {
        const current = e.currentTarget;
        const parentCategoria = current.closest('.swiper-votacao-fase-2');
        const swiperDaCategoria = parentCategoria.querySelectorAll('.swiper-slide');
        
        if (!current) return;

        // Verifique quantos atletas já foram selecionados
        let selectedAthletes = parentCategoria.querySelectorAll('.atleta-escolhido');
        
        // Caso o atleta já esteja selecionado, desmarque-o e remova o valor do input
        if (current.classList.contains('atleta-escolhido')) {
            current.classList.remove('atleta-escolhido');

            // Atualize os inputs removendo o valor do input correspondente ao atleta desmarcado
            selectedAthletes = parentCategoria.querySelectorAll('.atleta-escolhido'); // Recalcule a seleção após remover a classe

            // Limpar os inputs ocultos e preenchê-los novamente com os atletas restantes
            swiperDaCategoria.forEach((slide, index) => {
                const inputHidden = find(`[name="valor-indicado-${index}"]`);
                if (inputHidden) {
                    inputHidden.value = '';
                }
            });

        } else if (selectedAthletes.length < MAX_SELECTION) {
            // Se o número de atletas selecionados for menor que o máximo permitido, selecione mais um
            current.classList.add('atleta-escolhido');
        } else {
            // Se o número máximo de atletas já foi atingido, remova o mais antigo e selecione o novo
            selectedAthletes[0].classList.remove('atleta-escolhido');
            current.classList.add('atleta-escolhido');
        }
        
        // Atualize os inputs ocultos para armazenar os valores dos atletas selecionados
        selectedAthletes = parentCategoria.querySelectorAll('.atleta-escolhido'); // Recalcule os atletas selecionados
        selectedAthletes.forEach((atleta, index) => {
            const inputHidden = find(`[name="valor-indicado-${index + 1}"]`);
            if (inputHidden) {
                inputHidden.value = atleta.getAttribute('data-nome');
            }
        });
    });
});




submitButton?.addEventListener('click', () =>{
		form.submit()
})

	const swiperAtletas = new Swiper('.swiper-votacao-fase-2',{
		slidesPerView: 1.5,
		spaceBetween: 15,
		centeredSlidesBounds:true,
		centerInsufficientSlides:true,
		// slideToClickedSlide: true,
		centeredSlides:true,
			pagination: {
			el: '.swiper-pagination-custom-categoria-tecnica-2017',
			clickable: true,
			dynamicBullets: true,
						dynamicMainBullets: 3,
		},
		navigation: {
			nextEl: '.swiper-votacao-fase-2 .btn-next',
			prevEl: '.swiper-votacao-fase-2 .btn-prev',
		},
		breakpoints: {
			600:{
				slidesPerView: 'auto',
				spaceBetween: 30,
			}
		}
	})

}